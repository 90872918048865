.project_page_main{
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    background: linear-gradient(45deg, #414141, #000000);
    width: 100vw;
}

.error_message {
    background-color: #414141;
    border-radius: 2vmin;
    padding: 2vmax;
    margin: 3vmax;
    color: white;
}

.project-timeline{
    padding: 10px;
    background-color: aliceblue;
    border-radius: 10px;
    border: 3px solid black;
}

.project_footer_button {
    background-color: #000000;
    outline: 2px solid black;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-size: medium;
    position: absolute;
    right: 0;
    bottom: 0;
}

.image_wrapper{
    position: relative;
}

.project_footer_button:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.contributor_avatar {
    width: 50px;
    border-radius: 50%;
    margin: 5px;
}

.contributor_wrapper {
    display: inline-block;
}

.contributor_link {
    margin: 0 20px 0 0;
}

.clean {
    text-decoration: none;
    color: black;
}

.col {
    flex-direction: column;
}

.left {
    align-items: flex-start;
}

.bottom {
    align-items: flex-end;
}

.left_sidebar {
    padding: 10px;
    background-color: aliceblue;
    width: 20vw;

    border-radius: 10px;
    border: 3px solid black;
    position: relative;
}

.proj_img {
    background-color: black;
    object-fit: contain;
    width: 200px;
    height: 200px;
    display: block;
    margin-left:auto;
    margin-right:auto;
    border-radius: 50%;
    border: 3px solid black;
    align-self: center;
}

.issue_wrapper {
    background-color: lightgray;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
}

.inherit-width {
    width: 100%;
}
