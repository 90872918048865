@import url("https://fonts.googleapis.com/css?family=Poppins");

.projects_main{
    background: linear-gradient(45deg, #414141, #000000);
    width: 100vw;
}

.switcher{
    transform: scale(3);
    margin: 15%;
}

.left_switcher{
    border-radius: 50% 0 0 50%;
}

.right_switcher{
    border-radius: 0 50% 50% 0;
}

.flex{
    display: flex;
    align-items: center;
}

.text{
    color: white;
    white-space: nowrap;
}

.project_card{
    background-color: antiquewhite;
    width: 40vh;
    height: 20vh;
    min-width: 400px;
    min-height: 150px;
    border-radius: 20px;

    padding: 2vh;

    display: flex;
    flex-direction: column;

    margin: 10px;

    color: black;
}

.project_card_wrapper{
    display: inline-block;
}

.spread_out{
    justify-content: space-between;
}

.project_pool{
    color:forestgreen;
}

.project_card_btn{
    background-color: aliceblue;
    border: none;
    padding: 1vh;
    border-radius: 2vh;
    color: #000000;
    text-decoration: none;
    transition: all 0.2s ease;
    
    outline: 2px solid black;
}

.project_card_btn:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.spacer{
    flex-grow: 1;
}

.fonted{
    font-family: 'Poppins' , sans-serif;
}

.project_list{
    overflow-y: auto;
}

.proj_search{
    border-radius: 20px;
    margin: 10px !important;
    width: 200px;
}