body {
  background: linear-gradient(45deg, #414141, #000000);
  font-family: 'Poppins', sans-serif;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.cBlurb {
  margin: 10px 10px 10px 100px; /* add left margin of 100px */
  padding-right: 810px;
  justify-items: right;
  color: grey;


}

.links{
  
  color: grey;
}

.feedback-input {
  margin: 10px 10px 10px 100px; /* add left margin of 100px */
  padding: 10px;
  height: 150px;
  width: 520px;
  border-radius: 5px;
  background-color: rgba(128, 128, 128, 0.22);
  font-size: 16px;
  resize: both;
}

.feedback-input:focus {
  outline: none;
  background-color: rgba(97, 96, 96, 0.22);
  color: white;
}

input[type="submit"] {

  margin: 10px 10px 10px 100px; /* add left margin of 100px */
  padding: 10px 20px;
  background-color: rgba(128, 128, 128, 0.22);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  display: block;
  font-size: 16px;
}

input[type="submit"]:hover {
  background-color: rgba(128, 128, 128, 0.062);
}
