@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");

.login_main {
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#414141, #000000);
  background-size: 600% 100%;
  animation: gradient 16s linear infinite;
  animation-direction: alternate;
}                                                                                                 
 
/*Make the gradient move*/
@keyframes gradient{
  0% {background-position: 0%}
  100% {background-position: 100%}
}

header .logo{
  position: relative;
  max-width: 120px;

}

/*What holds the actual fields*/
.container{
  font-family: 'Oswald', serif;
  position: relative;
  max-width: 450px;
  width: 450px;
  background: white;
  border-radius: 10px;

}

.form{
  padding: 30px;
}

/*Login*/
.title{
  color: Grey;
  font-size: 27px;
  font-weight: 600;
  border-radius: 25px;
  position: relative;
}


.button .test{
  position: relative;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

i{
  color: white;
}

/*Forgot password and sign up text*/
.form a{
  color: grey;
  text-decoration: none;
  font-size: 14px;
  padding-left: 72px;
  font-weight: 800px;
}



/*When you hover any of the links*/
.form a:hover{
  color: grey;
  text-decoration: underline;
  font-size: 14px;
}

/*Login Button*/
.button .test{
  border: none;
  background-image: grey;
  color: white;
  letter-spacing:1px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 500;

  cursor: pointer;
  
  transition: all 0.3s ease; 
  animation: 10s rotate linear infinite;
  background: linear-gradient(45deg, #f04714, #a1134a, #187597, #189276, #c49727,#c52a03);
  background-size: 600% 100%;
  animation: gradient 16s linear infinite;
  animation-direction: alternate;
}

/*Hover animation for login button*/
.button .test:hover{
  transform: scale(1.05);
}
